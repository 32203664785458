import { MyFirebase } from './chat/Config'

const SECOND_WATCHED_REF = 'secondsWatchedData'

const minutesWachedRef = MyFirebase.database().ref(SECOND_WATCHED_REF)

type Timestamp = number

type Batch = {
  [secondInVideo: number]: Timestamp
}

// TODO clientId
export const createSessionSecondWatched = (
  workoutId: number,
  userId: number,
  sessionId: string,
) => {
  const data = {
    userId,
    workoutId,
    clientName: process.env.GATSBY_CLIENT_NAME_HEADER,
    minutesWached: [],
  }
  minutesWachedRef
    .child(sessionId)
    .set({ ...data })
    .then(() => {
      console.log('session created in firebase')
    })
}

export const saveBatchToFirebase = (sessionId: string, batch: Batch) => {
  const secondInVideo = Number(Object.keys(batch)[0])
  const timestamp = batch[secondInVideo]
  MyFirebase.database()
    .ref(`${SECOND_WATCHED_REF}/${sessionId}/secondWatched`)
    .push({ secondInVideo, timestamp })
}
